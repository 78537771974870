import React from 'react'

const NotFound = () => {
  return (
    <div className='flex flex-col justify-center items-center align-middle h-screen'>
    <h1 className='text-4xl text-highlight-font'>Oops, Seems like you got lost</h1>
    <p>This page is what we call a 404 in the biz</p>
    </div>
  )
}

export default NotFound 
